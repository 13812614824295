import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/91.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/92.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/93.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/94.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/95.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/96.jpg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/97.jpg"
          alt="First slide"
        />

      </Carousel.Item>
 
    </Carousel>
  );
}

export default PowerCarousel;