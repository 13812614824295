import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='goals-carousel concrete-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/161.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/162.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/163.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/164.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/165.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      
    </Carousel>
  );
}

export default Residential1;