import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Packages Offered | Wanway Solutions Ltd';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url("./images/15.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Packages Offered</h2>
            
        </div>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
                 <p className='home-p'>
      Our system uses the most recent technology in the market making our packages second to none. We believe in quality and affordability giving everyone a chance to secure their property. The following packages are offered to our customers.
</p>





 
        </div>

    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>WANWAY I</h3>                    
 <p className='service-desc'>This package is tailor-made for personal vehicles. Its main features are: </p>
<ul>
    <li>SOS panic button. </li> 
<li>Engine ON/OFF alerts.</li>
<li>Engine cut off.</li>
<li>Exact location of car when stolen.</li>
<li>Username and password to access the system.</li>

</ul>
 {/* <p className='service-desc'>With guarantee on all our electrical supplies and dependable assurance on all our electrical solutions.</p> */}
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>WANWAY II  
</h3>
 <p className='service-desc'>This is a fleet management package tailored for: transport companies, leasing and car rental companies, delivery and distribution companies. Its main features are:</p>
<ul>
<li>Current position report.</li>	
<li>Engine ON/OFF alerts.</li>	
<li>Speed reports and alerts.</li>	
<li>Mileage reports.</li>	 
<li>Geo-fencing control.</li>	
<li>Stop report.</li>	
<li>SOS panic button.</li>	 
<li>Engine cut off.</li>	
<li>Exact location of car when stolen.</li>	
<li>Username and password to access the system.</li>	

</ul>
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>WANWAY III  </h3>
                       <p>
                      This is a fleet management package tailored for long distance transport operators, trailers and haulers. This package has the following:
                </p>
           <ul>
           <li>Current location report.</li> 
<li>Engine ON/OFF alerts.</li>
<li>Tracking by time interval.</li>
<li>Built-in motion sensor for power saving.</li>
<li>SOS panic button.</li>
<li>	Geo-fencing control.</li>
<li>	Low battery alert.</li>
<li>	Speeding alert.</li>
<li>	Engine Cut.</li>
<li>	Wiretapping (optional).</li>
<li>	Alert when vehicle enters/exits GPS blind area.</li>
<li>	Alert when vehicle is turned on.</li>
<li>	Sound recording.</li>

</ul>
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>WANWAY IV</h3>
                  <p>
                   This is tailor made for motor cycles tracking incase of theft and also to know the location. This package has the following:
                    </p>
                        <ul>
<li>Exact location of motorcycle when stolen.</li>
<li>User name and password to access the system.</li>
<li>Low battery alert.</li>
<li>Current location report.</li>
<li>Tracking by time interval.</li>
<li>Built-in motion sensor for power saving.</li>

</ul>
                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>

     
           </div>

    </section>
    </>
  )
}

export default Services