import Carousel from 'react-bootstrap/Carousel';

function ClassRoomBlocks() {
  return (
    <Carousel  indicators={false} className="goals-carousel concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

     


    </Carousel>
  );
}

export default ClassRoomBlocks;