import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | Kas Electrical and Plumbing Services';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
          {/* <p className='clients-para'><strong>RAK ENGINEERING LTD</strong> has in the recent past worked on a number of projects and has registered commendable success among clients that include:</p> */}
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/32.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/33.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/34.jpg' alt='client' className='client-image'></img>
           </article>
{/* 
            <article className='client'>
                      <img src='./images/TERRAIN.png' alt='client' className='client-image'></img>
                        <p><strong>TERRAIN SERVICES LTD</strong></p>
            </article> */}

            <article className='client'>
                      <img src='./images/35.jpg' alt='client' className='client-image'></img>
                        
            </article>

            <article className='client'>
                      <img src='./images/36.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/37.png' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/38.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/39.png' alt='client' className='client-image'></img>            
            </article>
           

            <article className='client'>
                      <img src='./images/40.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/41.jpg' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/42.png' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/43.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/44.png' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/45.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/46.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/47.png' alt='client' className='client-image'></img>            
            </article>
            <article className='client'>
                      <img src='./images/48.png' alt='client' className='client-image'></img>            
            </article>
             <article className='client'>
                      <img src='./images/49.png' alt='client' className='client-image'></img>            
            </article>
          
          
          
          </div> 
    </section>
  )
}

export default Clients