import Carousel from 'react-bootstrap/Carousel';

function WareHouse() {
  return (
    <Carousel  indicators={false} className="goals-carousel concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/131.jpg"
          alt="Third slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/132.jpg"
          alt="Third slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/133.jpg"
          alt="Third slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/134.jpg"
          alt="Third slide"
        />  
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/135.jpg"
          alt="Third slide"
        />  
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WareHouse;