import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = 'CCTV Systems | Kas Electrical and Plumbing Services';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2>  */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>CCTV Systems</h3> 
                   <p> IP and analogue cameras, dome, bullet
NVRs and DVRs cameras.</p>                    
{/* <ul>
  <li>Commercial Standby generator.</li>
<li>Facility Electrical Inspection and Power Audit.</li>
<li>Industrial electrical designs and consultancy.</li>
<li>Security systems and Fire Alarms.</li>
<li>Power Surge and lightening protection.</li>
<li>Solar Irrigation systems.</li>
<li>Custom Electrical wiring for residential, office & health facilities.</li>
<li>Lighting and Electrical Appliances; Air conditioners.</li>
<li>Industrial stabilizers repair and maintenance.</li>
<li>Reducing energy consumption.</li>
<li>Emergency electrical works.</li>
</ul> */}
 {/* <p className='service-desc'>With guarantee on all our electrical supplies and dependable assurance on all our electrical solutions.</p> */}
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Solar Power Services
</h3>
<ul>
<li>Solar Power backup systems.</li>
<li>Solar Powered EV Charging Stations.</li>
<li>Solar Water Irrigation systems.</li>
<li>Solar water heating systems.</li>
<li>Inverter system repair and upgrade.</li>
<li>Grid-Tied Solar Systems.</li>
<li>Off-Grid Solar Systems.</li>
</ul>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Services</h3>
                       <p><strong>Beda Civil Works & Contractors Limited</strong> continues its success by offering an unparalleled level of diversity and experience in Construction and Maintenance of Road Infrastructure. </p>                 
                <p> We have the capacity and skills to undertake any road construction or repair project. We have excellent expertise in Structural & Geometric Road Design, Road & Highway Construction, Surface Dressing, Asphalt and concrete paving, Pothole Patching, General Road and Highway Maintenance and Road Marking & Signs.</p>
                  <ul>
                   <li>Facility Water Supply Network Design.</li>   
<li> Water Heater Services.</li>
<li>Leakage repairs.</li>
<li>Angle stop repairs.</li>
<li>Toilet and bathroom repairs.</li>
<li>Sewer Main Installation and repair.</li>
<li> Drain Cleaning.</li>
<li>Gas piping.</li>
</ul>
                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>IT Services</h3>
                  <p>We are trusted in bridge construction in Uganda. Our expertise is in delivery of innovative and quality solutions for bridges. Over the years, we have successfully executed several projects in different parts of Uganda.</p>
                        <ul>
                 <li>Fiber Systems.</li> 
<li>CCTV Systems. <Link className='read-more-link'>Read more...</Link></li> 
<li>Wall and Ceiling projectors.</li> 
<li> Structured Cabling. <Link className='read-more-link'>Read more...</Link></li> 
<li>Wi-Fi Solutions. <Link className='read-more-link'>Read more...</Link></li> 
<li>Rack Cabinets and Accessories.</li> 
<li>ICT equipment servicing and repair.</li> 

</ul>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div> */}

           {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Surveying <br></br>(Quantity & Land Surveying)</h3>
                
                <p>We provide our clients with exceptional, full service land surveying services and solutions. An accurate, forward looking, detailed land survey, conducted by experienced and knowledgeable professionals, is essential to the success and efficiency of planning, design, and site development.</p>
                  <p>If you’re a current or future homeowner, there are many of our services you can take advantage of. From the survey you need when you buy a new house, to locating your property corners so that you can build that new fence, we can serve you.</p>
                  <p>Are you planning a commercial project? We are experienced in offices, retail and residential developments and many others, from boundary surveys to annexation, subdividing, site design and construction layout.</p>
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div> */} 


            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Architectural Works</h3>
                     <p> If you are looking for beautiful unique building designs, our skills are right for you beautifully adopted for everyone’s design tastes, and can be as simple or complex as you want it to be.</p>
                     <p>We truly care about our users & end products which is why our clients love our work! Our work includes production of many different designs. Each design stands the test of time and saves a developer’s money with all the design concepts we provide.</p>
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Paving Works
</h3>
                <p>
                We understand that a paved area is an investment, and we believe it’s a good one. If done correctly, a properly paved area will withstand the elements of both man and nature for many years. It will also add value to your home or business and help you make a great first impression on your visitors.
                </p>
                <p>If you’re in need of a new paved area, give us a call. Before we begin our paving process, we’ll talk to you about your vision and offer our professional opinion on things like placement, size, and price. We’ll also make sure that any drainage issues are taken care of and that your property has a solid foundation so that cars can drive on it safely. </p>
                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Works</h3>
                     <p> Whatever your plumbing needs may be, count on us! We have been serving both residential and commercial properties in Uganda. We pride ourselves on our plumbing engineers and technicians considering their high level of expertise.</p>
                     <p>Dependability and innovation is part of what makes us unique.</p>
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Project Management
</h3>
                <p>
                Our multidisciplinary project teams and unique technical solutions will ensure that all projects meet technical, economic, environmental and safety requirements. Our specialists work in close coordination with local partners to ensure end-to-end project management, from site investigation and feasibility phases through to final delivery to the operator.
                </p>
                <p><strong>Beda Civil Works & Contractors Limited</strong> also profits from its experience in the products developments sectors to introduce system engineering processes and tools to master the complexity of projects and to optimise costing and scheduling.</p>
                  </article>

                  <article className='service-slides'>
                    <AC></AC>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Farming Services</h3>
                     <p>We provide the very best in powerful farm machinery and vehicles such as genuine Massey Fergusson tractors, ploughs and harrows, crop sprayers and post-hole diggers.</p>
                     <p>We provide farming implements and tools for small, medium and large-scale agriculture. All our equipment is chosen for durability and low running costs, and they are backed by the expert installation services and support of field technical team. </p>
                  </article>

                  <article className='service-slides'>
                    <Painting></Painting>
                 
                  </article>     
            </div> */}

          
            
           </div>

    </section>
  )
}

export default Services