import Carousel from 'react-bootstrap/Carousel';

function WareHouse() {
  return (
    <Carousel  indicators={false} className="goals-carousel concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/125.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/126.jpg"
          alt="Third slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/136.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/137.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/138.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/138.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/139.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/140.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/141.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WareHouse;