import Carousel from 'react-bootstrap/Carousel';

function WareHouse() {
  return (
    <Carousel  indicators={false} className="goals-carousel concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/127.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/128.jpg"
          alt="Third slide"
        /> 
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/129.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/130.jpg"
          alt="Third slide"
        />   
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WareHouse;