import React,{useState, useEffect} from 'react'

import {FaArrowUp} from 'react-icons/fa';

const BackToTop = () => {
    let [topScroll, setTopScroll]= useState(false);


useEffect(()=>{

window.addEventListener('scroll',()=>{
          if(window.scrollY > 800){
            setTopScroll(true);
          }
          else{
            setTopScroll(false);
          }

});


},[]);



let scrollFunc = () =>{
window.scrollTo({
   top:0,

});


}


  return (
    
    <button  className={`${topScroll?'back-top-cover': 'hide'}`} onClick={scrollFunc}>      
        <span className='top-arrow'><FaArrowUp></FaArrowUp></span>    
    </button>
    
  )
}

export default BackToTop