import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes, FaYammer} from 'react-icons/fa';
// import DropDown from '../components/AboutDropDown';
// import NavItems from '../pages/resources/NavItems';
// import AboutDropDown from './AboutDropDown';

const Nav = () => {
  
  let [menu, setMenu] = useState(true);
  let [close, setClose] = useState(false);
  let [dropDown, setDropDown] = useState(false);
  let [fixedNav, setFixedNav] = useState(false);

let linksContainer = useRef(null);
let linksContainerInner = useRef(null);


let menuFunc =()=>{
     setMenu(false);
     setClose(true);
   let linksCover = linksContainer.current;
   
   let innerHeight = linksContainerInner.current.getBoundingClientRect().height;
   linksCover.style.height = `${innerHeight}px`;
}

let closeFunc =()=>{
     setMenu(true);
     setClose(false);
     let linksCover = linksContainer.current;
     linksCover.style.height = 0;
}

let activateDropDown = () =>{
  setDropDown(true);

}


let removeDropDown = ()=>{
  setDropDown(false);
}




useEffect(()=>{


window.addEventListener('scroll',()=>{
let yScroll = window.pageYOffset;


// if(yScroll >= 500){
//   setFixedNav(true);
// }
// else{
//   setFixedNav(false);
// }

});




},[])


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
  // <>
    // <nav className={`${fixedNav?'fixed-nav':'nav'}`}>
        <nav className="fixed-nav">
         <div className='nav-center'>
             <div className='nav-header'>
                  <div className='logo-container'>
              <img src='./images/logo.jpg' alt='logo' className='logo'></img>
            </div>
            <div className={`${menu?'nav-toggle': 'nav-toggle hide'}`}     onClick={menuFunc}>
                      <FaBars></FaBars>
            </div>
            <div className={`${close?'nav-close': 'nav-close hide'}`}   onClick={closeFunc}>
                      <FaTimes></FaTimes>
            </div>
              
              </div>
              <div className='links-container' ref={linksContainer}>
                <ul className='nav-links' ref={linksContainerInner}>
                   <li onClick={scrollFunc}>   <Link to='/' className='nav-link1' onClick={closeFunc}  >Home</Link></li>
                    {/* <li className='drop-down-li'   onMouseEnter={activateDropDown} onMouseLeave={removeDropDown}  onClick={scrollFunc}> <Link to='#about' className='nav-link1 drop-down-parent' > About Us</Link> 
                                                    <ul className={`${dropDown? 'drop-down': 'drop-down hide'}`}    >
                                                   
                                                      <li onClick={removeDropDown}><Link className='drop-down-link' to='who-we-are' onClick={closeFunc}> Who We Are</Link></li>
                                                      <li  onClick={removeDropDown}><Link className='drop-down-link' to='team' onClick={closeFunc}>Our Team</Link></li>  
                                                       <li  onClick={removeDropDown}><Link className='drop-down-link' to='certifications' onClick={closeFunc}> Certifications</Link></li>  
                                                       <li  onClick={removeDropDown}><Link className='drop-down-link' to='equipment' onClick={closeFunc}>Equipment</Link></li>                            
                                                      
                                                      </ul>
                    
                    </li> */}
                     <li onClick={scrollFunc}> <Link to='about' className='nav-link1' onClick={closeFunc}>About Us</Link></li>
                      <li onClick={scrollFunc}> <Link to='services' className='nav-link1' onClick={closeFunc}>Our Services</Link></li>
                      <li onClick={scrollFunc}> <Link to='packages' className='nav-link1' onClick={closeFunc}>Our Packages</Link></li>
                      {/* <li onClick={scrollFunc}> <Link to='#clients' className='nav-link1' onClick={closeFunc}>Our Clients</Link></li> */}
                      {/* <li onClick={scrollFunc}> <Link to='completed-projects' className='nav-link1' onClick={closeFunc}>Completed Projects</Link></li> */}
                      {/* <li className='drop-down-li'   onMouseEnter={activateDropDown} onMouseLeave={removeDropDown}  onClick={scrollFunc}> <Link to='#' className='nav-link1 drop-down-parent' > Projects</Link> 
                                                    <ul className={`${dropDown? 'drop-down': 'drop-down hide'}`}    >
                                                   
                                                      <li onClick={removeDropDown}><Link className='drop-down-link' to='completed-projects' onClick={closeFunc}> Completed</Link></li>
                                                      <li  onClick={removeDropDown}><Link className='drop-down-link' to='ongoing-projects' onClick={closeFunc}>Ongoing</Link></li>  
                                                        
                                                      
                                                      </ul>
                    
                    </li> */}
                      <li onClick={scrollFunc}> <Link to='inquiries' className='nav-link1' onClick={closeFunc}>Write To Us</Link></li>
{/* {NavItems.map((navItem)=>{
          if(navItem.name === 'About')
          return <li key={navItem.id}  onMouseEnter={activateDropDown} onMouseLeave={removeDropDown}  className={navItem.cName}>
      <Link   to={navItem.path}    >{navItem.name}
     {dropDown && <AboutDropDown ></AboutDropDown>}
      </Link>
          
       </li>
   

      return <li key={navItem.id}  className={navItem.cName}>
      <Link   to={navItem.path}>{navItem.name}</Link>
       </li>
})
} */}

                </ul>

              </div>
         </div>

         
    </nav>
   
    // {/* </> */}
  )
}

export default Nav