import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Ongoing Projects | Docksid 3 Construction Limited';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url("./images/15.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Ongoing Projects</h2>
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container'>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Residential Building in Lira</h3>
                  {/* <p>We are trusted in bridge construction in Uganda. Our expertise is in delivery of innovative and quality solutions for bridges. Over the years, we have successfully executed several projects in different parts of Uganda.</p>
                        <ul>
                <li>Client: Musana Empowerment Centre</li> 
<li>Location: Kaliro</li>
<li>Period: Feb 2023 - to date</li>
<li>Value: ugx 500,000,000</li>
</ul> */}
                  </article>

                  <article className='service-slides'>
                    <Cleaning></Cleaning>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Residential Building in Kamuli</h3>
                
                
                  </article>

                  <article className='service-slides'>
                    <Supply></Supply>
                 
                  </article>     
            </div>


            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Architectural Works</h3>
                     <p> If you are looking for beautiful unique building designs, our skills are right for you beautifully adopted for everyone’s design tastes, and can be as simple or complex as you want it to be.</p>
                     <p>We truly care about our users & end products which is why our clients love our work! Our work includes production of many different designs. Each design stands the test of time and saves a developer’s money with all the design concepts we provide.</p>
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Paving Works
</h3>
                <p>
                We understand that a paved area is an investment, and we believe it’s a good one. If done correctly, a properly paved area will withstand the elements of both man and nature for many years. It will also add value to your home or business and help you make a great first impression on your visitors.
                </p>
                <p>If you’re in need of a new paved area, give us a call. Before we begin our paving process, we’ll talk to you about your vision and offer our professional opinion on things like placement, size, and price. We’ll also make sure that any drainage issues are taken care of and that your property has a solid foundation so that cars can drive on it safely. </p>
                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Works</h3>
                     <p> Whatever your plumbing needs may be, count on us! We have been serving both residential and commercial properties in Uganda. We pride ourselves on our plumbing engineers and technicians considering their high level of expertise.</p>
                     <p>Dependability and innovation is part of what makes us unique.</p>
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Project Management
</h3>
                <p>
                Our multidisciplinary project teams and unique technical solutions will ensure that all projects meet technical, economic, environmental and safety requirements. Our specialists work in close coordination with local partners to ensure end-to-end project management, from site investigation and feasibility phases through to final delivery to the operator.
                </p>
                <p><strong>Beda Civil Works & Contractors Limited</strong> also profits from its experience in the products developments sectors to introduce system engineering processes and tools to master the complexity of projects and to optimise costing and scheduling.</p>
                  </article>

                  <article className='service-slides'>
                    <AC></AC>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Farming Services</h3>
                     <p>We provide the very best in powerful farm machinery and vehicles such as genuine Massey Fergusson tractors, ploughs and harrows, crop sprayers and post-hole diggers.</p>
                     <p>We provide farming implements and tools for small, medium and large-scale agriculture. All our equipment is chosen for durability and low running costs, and they are backed by the expert installation services and support of field technical team. </p>
                  </article>

                  <article className='service-slides'>
                    <Painting></Painting>
                 
                  </article>     
            </div> */}

          
            
           </div>

    </section>
    </>
  )
}

export default Services