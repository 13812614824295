import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/119.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/120.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/121.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/122.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/123.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>

      
    </Carousel>
  );
}

export default PowerCarousel;